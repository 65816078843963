import React, { useState, useEffect, createContext } from "react";
import cv from "./assets/cv.pdf";
// libraries
import { Route, Switch } from "react-router-dom";
import firebase from "./Firebase";
// import AOS from "aos";
// import "aos/dist/aos.css";
// components
import Header from "./components/Header/Header";
import SecondaryMenu from "./components/Menu/SecondaryMenu";
// pages
import HomePage from "./pages/HomePage";
import PortfolioList from "./pages/PortfoliosList/PortfolioList";
import PortfolioDetail from "./pages/PortfolioDetail/PortfolioDetail";
// import BlogList from "./pages/BlogList/BlogList";
// import BlogDetail from "./pages/BlogDetail/BlogDetail";
import Error from "./pages/Error";

// AOS.init();

export const PortfoliosContext = createContext();
export const BlogsContext = createContext();

function App() {
  const portfoliosCollection = firebase.firestore().collection("portfolios");
  const blogsCollection = firebase.firestore().collection("blogs");

  const [data, setData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  function getData() {
    portfoliosCollection.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setData(items);
    });
  }
  function getBlogsData() {
    blogsCollection.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setBlogsData(items);
      console.log(items);
    });
  }

  useEffect(() => {
    getData();
    getBlogsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <PortfoliosContext.Provider value={data}>
        <BlogsContext.Provider value={blogsData}>
          <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} cv={cv} />
          <SecondaryMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <Switch>
            <Route exact path="/" component={HomePage}></Route>
            <Route exact path="/portfolios" component={PortfolioList}></Route>
            <Route path="/portfolios/:id" component={PortfolioDetail}></Route>
            {/* <Route exact path="/blogs" component={BlogList}></Route> */}
            {/* <Route path="/blogs/:id" component={BlogDetail}></Route> */}
            <Route component={Error}></Route>
          </Switch>
        </BlogsContext.Provider>
      </PortfoliosContext.Provider>
    </React.Fragment>
  );
}

export default App;
