import React from 'react'
import './About.css'
import aboutImg from '../../assets/about.png'
import { FaQuoteLeft } from 'react-icons/fa'

export default function About() {
    return (
        <div className='section about'>
            <div className="title">
                <h2>About Me</h2>
                <span>“I do my best, so that I can't blame myself for anything.”</span>
            </div>
            <div className="about-content">
                <div className="about-img">
                    <img src={aboutImg} alt="" className='img-fluid' />
                </div>
                <div className='about-desc'>
                    <p className='quote'>
                        <FaQuoteLeft className='icon' />
                        <span>Learning the new thing everywhere you work and keep building your skills to new level is the key to your success.</span>
                    </p>
                    <p>I have been working in this field Since late 2018 A.D. I had worked with different companies for past some time, worked in different projects and gained experience and skills. I prefer working both with team and single in order to complete my project regarding the work requirement. I believe in learning by doing every where i work with the positive attitude in order to obtained the targeted business growth as well as personal growth.</p>
                    <p>I generally prefer working in the environment where people respect each other and helps each other grow. I believe it is only mutually people can grow faster. There are many challenges in this industry so in order to grow i am open to feedback, suggestion and advice.</p>

                </div>
            </div>
        </div>
    )
}
