import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBI-NdnDhjDM2XJj56le5hPs5HjMFPGVLM",
  authDomain: "personal-portfolio-568d4.firebaseapp.com",
  databaseURL: "https://personal-portfolio-568d4-default-rtdb.firebaseio.com",
  projectId: "personal-portfolio-568d4",
  storageBucket: "personal-portfolio-568d4.appspot.com",
  messagingSenderId: "1061854236181",
  appId: "1:1061854236181:web:4ffc70cf6d45c8559fc81c",
  measurementId: "G-Q7M3L9QR2N",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
