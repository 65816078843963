import React from 'react'
import { Link } from 'react-router-dom'
import './Menu.css'

export default function SecondaryMenu({ menuOpen, setMenuOpen }) {
    return (
        <div className={'navigation-menu ' + (menuOpen && 'active')}>
            <ul>
                <li className='about-link'>
                    <span className='about-link-bg'>HOME</span>
                    <Link to="/" onClick={() => { setMenuOpen(false) }}>Home</Link>
                </li>
                <li className='portfolio-link'>
                    <span className='portfolio-link-bg'>PORTFOLIOS</span>
                    <Link to="/portfolios" onClick={() => { setMenuOpen(false) }}>Portfolios</Link>
                </li>
                <li className='contact-link'>
                    <span className='contact-link-bg'>BLOGS</span>
                    <Link to="/blogs" onClick={() => { setMenuOpen(false) }}>Blogs</Link>
                </li>
            </ul>
        </div>
    )
}
