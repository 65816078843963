import React, { useContext, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { PortfoliosContext } from "../../App";
import './PortfolioDetail.css'
import { FaLongArrowAltLeft } from "react-icons/fa";


function PortfolioDetail() {
    const portfolios = useContext(PortfoliosContext);
    const { id } = useParams()

    useEffect(() => {
        if (window.scrollY > 1) {
            window.scrollTo(0, 0)
        }
    }, [])

    return (
        <div className='section portfolio-detail'>
            <Link to="/portfolios" className='btn primary-btn back-link'> <FaLongArrowAltLeft className='icon' /> More Portfolios</Link>
            {portfolios.map(portfolio => (
                portfolio.id.toString() === id.toString() ?
                    <div className="portfolio-content" key={portfolio.id}>
                        <div className="title">
                            <h2>{portfolio.name}</h2>
                            <br />
                            <p><strong style={{ fontWeight: 700 }}>Portfolio Type</strong> - {portfolio.type} </p>
                            <br />
                            <p><strong style={{ fontWeight: 700 }}>Major Languages</strong> - | {portfolio.skills.map(skill => { return `${skill} | ` })}
                            </p>
                        </div>
                        <div className="portfolio-image">
                            <img src={portfolio.image} alt="" />
                        </div>
                    </div>
                    : ''
            ))}
        </div>
    )
}

export default PortfolioDetail