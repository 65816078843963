import React from "react";
import '../index.css'
import { Link } from "react-router-dom";

function Error() {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        textAlign: "center"
      }}
    >
      <h1 data-aos="zoom-in" style={{ marginBottom: "15px" }}> Page Not Found. </h1>
      <Link
        to="/"
        className="btn primary-btn"
      >
        Go To Home Page
      </Link>
    </div>
  );
}

export default Error;
