import React from 'react'
import './Contact.css'
import contactImage from '../../assets/contact.png'
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { BsArrowRightShort } from "react-icons/bs";

export default function Contact({ scrollTop }) {
    return (
        <div className='section contact' id='contact'>
            <div className="title">
                <h2>Contact Me</h2>
                <span>“Simple hello could lead to a million things.”</span>
            </div>
            <div className="contact-box">
                <div className="spacer"></div>
                <div className="contact-info">
                    <div className="address">
                        <h3>Address</h3>
                        <p>Dattatraya Ward no. 9, Bhaktapur</p>
                        <p>Bagmati State, Nepal</p>
                    </div>
                    <div className="social-info">
                        <h3>Contact Me On</h3>
                        <div>
                            <span>Email : <a href='mailto:sdpulak123@gmail.com' className='link'> sdpulak123@gmail.com </a></span>
                            <div className="social-icons">
                                <a target="_blank" href="https://www.facebook.com/sdp.ulak" className="icon-wrapper" rel="noreferrer">
                                    <FaFacebookF className="icon" />
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/in/sudip-ulak-861675183/" className="icon-wrapper" rel="noreferrer">
                                    <FaLinkedinIn className="icon" />
                                </a>
                                <a target="_blank" href="https://www.instagram.com/sdpulak/" className="icon-wrapper" rel="noreferrer">
                                    <FaInstagram className="icon" />
                                </a>
                                <a target="_blank" href="mailto:sdpulak123@gmail.com" className="icon-wrapper" rel="noreferrer">
                                    <FaRegEnvelope className="icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right">
                        <i>&copy;copyright 2022 Sudip Ulak. All rights are reserved</i>
                        <br />
                        <i>Designed and Developed by Sudip Ulak</i>
                    </div>
                </div>
                <div className="contact-img">
                    <img src={contactImage} alt="" />
                </div>
                <div className="spacer"></div>
            </div>
            <div className="scroll-top">
                <a href="/" onClick={scrollTop}>
                    <span>Scroll To Top</span> &nbsp; <BsArrowRightShort className='icon' />
                </a>
            </div>
        </div >
    )
}
