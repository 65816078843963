import React from "react";
import "./Header.css";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { CgMenuGridO, CgClose } from 'react-icons/cg';
import { Link } from "react-router-dom";

export default function Header({ menuOpen, setMenuOpen, cv }) {
  return (
    <div className="nav-bar">
      <div className="header">
        <div className="left">
          <Link to="/" className="logo-wrapper">
            <span className="logo">Sudip</span>
          </Link>
          <span className="design-dash"> - </span>
          <span className="social-icons">
            <a target="_blank" href="https://www.facebook.com/sdp.ulak" className="icon-wrapper" title="Facebook" rel="noreferrer">
              <FaFacebookF className="icon" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/sudip-ulak-861675183/" className="icon-wrapper" title="LinkedIn" rel="noreferrer">
              <FaLinkedinIn className="icon" />
            </a>
            <a target="_blank" href="https://www.instagram.com/sdpulak/" className="icon-wrapper" title="Instagram" rel="noreferrer">
              <FaInstagram className="icon" />
            </a>
            <a target="_blank" href="mailto:sdpulak123@gmail.com" className="icon-wrapper" title="Email" rel="noreferrer">
              <FaRegEnvelope className="icon" />
            </a>
          </span>
        </div>
        <div className="right">
          <a href={cv} className="btn secondary-btn" title="Download CV" download>
            Download CV
          </a>
          {menuOpen ? <CgClose className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)} /> : <CgMenuGridO className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)} />}
        </div>
      </div >
    </div>
  );
}
