import React from 'react'
import './Skills.css'

function Skills() {
    return (
        <div className="section skills" id='skills'>
            <div className="title">
                <h2>Skills</h2>
                <span>“Every skill you acquire doubles your odds of success.”</span>
            </div>
            <div className="skills-line">
                <span>2018</span>
                <span>2022</span>
            </div>
            <div className="skills-content">
                <div className="skill-card">

                    <h3>UI Designing</h3>
                    <ul>
                        <li>Adobe Photoshop, XD, Figma</li>
                        <li>Responsive Website</li>
                        <li>Git, Github </li>
                        <li>Bitbucket, GitLab</li>
                    </ul>
                </div>
                <div className="skill-card">
                    <h3>Website Development</h3>
                    <ul>
                        <li>HTML, CSS </li>
                        <li>JavaScript / JQuery</li>
                        <li>Bootstrap, Materalize</li>
                        <li>SASS (CSS pre processor)</li>
                    </ul>
                </div>
                <div className="skill-card">
                    <h3>Web App Development</h3>
                    <ul>
                        <li>React Js / Zustand</li>
                        <li>Vue Js / Vuex</li>
                        <li>Vuetify / Element UI</li>
                        <li>Wordpress Theme Development</li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Skills