import React, { useContext, useEffect } from "react";
import "./PortfolioList.css";
import { PortfoliosContext } from "../../App";
import { Link } from "react-router-dom";

function PortfolioList() {
  const portfolios = useContext(PortfoliosContext);
  useEffect(() => {
    if (window.scrollY > 1) {
      window.scrollTo(0, 0)
    }
  }, [])
  return (
    <div className="section portfolio-list">
      <div className="title">
        <h2>Portfolios</h2>
        <span>
          “Career is a portfolio of projects that teach you new skills.”
        </span>
      </div>
      <div className="feature-portfolios">
        {portfolios.map((portfolio) => (
          <Link
            to={`/portfolios/${portfolio.id}`}
            className="card"
            key={portfolio.id}
          >
            <div className="img-box">
              <img src={portfolio.image} alt="" />
            </div>
            <div className="card-content">
              <h3 className='portfolio-name'>{portfolio.name}</h3>
              <span>| {portfolio.skills.map(skill => { return `${skill} | ` })}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default PortfolioList;
