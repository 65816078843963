import React from "react";
import './Banner.css'
import bannerImage from '../../assets/banner.png'

function Banner({ cv }) {
  return (
    <div className="section banner">
      <div className="banner-content">
        <h2>I am Sudip Ulak</h2>
        <h1>Front End Developer</h1>
        <p>I am focused on crafting the clean, user-friendly and responsive experiences for the remarkable business growth.</p>
        <p>My Career objective is to provide the good exposure in my work with the positive attitude, team work and consistent improvement in building web experiences.</p>
        <a href={cv} className="btn primary-btn" download> Download CV </a>
      </div>
      <div className="banner-image">
        <div className="img-container">
          <img src={bannerImage} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
