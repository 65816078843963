import React, { useContext } from 'react'
import './Portfolio.css'
import { Link } from 'react-router-dom'
import { PortfoliosContext } from "../../App";

export default function Portfolio() {
    const portfolios = useContext(PortfoliosContext)

    return (
        <div className='section portfolio'>
            <div className="title">
                <h2>Portfolio</h2>
                <span>“Career is a portfolio of projects that teach you new skills.”</span>
            </div>
            <div className='feature-portfolios'>
                {portfolios.map(portfolio => (
                    portfolio.featured ?
                        <a href={`#/portfolios/${portfolio.id}`} className="card" key={portfolio.name}>
                            <div className="img-box">
                                <img src={portfolio.image} alt="" />
                            </div>
                            <div className="card-content">
                                <h3 className='portfolio-name'>{portfolio.name}</h3>
                                <span>| {portfolio.skills.map(skill => { return `${skill} | ` })}</span>
                            </div>
                        </a>
                        : ''
                ))}
            </div>
            <div className="portfolio-btn">
                <Link to="/portfolios" className='btn primary-btn'>View More Projects</Link>
            </div>
        </div>
    )
}
