import React from 'react'
import { scroller } from "react-scroll";
import './Menu.css'

export default function Menu({ menuOpen, setMenuOpen, scrollTop }) {
  function scrollToSection(e, section) {
    e.preventDefault();
    scroller.scrollTo(section, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    setMenuOpen(false);
  };
  return (
    <div className={'navigation-menu ' + (menuOpen && 'active')}>
      <ul>
        {/* <li className='home-link'>
          <span className='home-link-bg'>HOME</span>
          <a href="/" onClick={scrollTop}>Home</a>
        </li> */}
        <li className='about-link'>
          <span className='about-link-bg'>ABOUT</span>
          <a href="/" onClick={(e) => scrollToSection(e, 'about')}>About</a>
        </li>
        <li className='skills-link'>
          <span className='skills-link-bg'>SKILLS</span>
          <a href="/" onClick={(e) => scrollToSection(e, 'skills')}>Skills</a>
        </li>
        <li className='portfolio-link'>
          <span className='portfolio-link-bg'>PORTFOLIOS</span>
          <a href="/" onClick={(e) => scrollToSection(e, 'portfolio')}>Portfolios</a>
        </li>
        {/* <li className='blog-link'>
          <span className='blog-link-bg'>BLOGS</span>
          <a href="/" onClick={(e) => scrollToSection(e, 'blog')}>Blogs</a>
        </li> */}
        <li className='contact-link'>
          <span className='contact-link-bg'>CONTACT</span>
          <a href="/" onClick={(e) => scrollToSection(e, 'contact')}>Contact</a>
        </li>
      </ul>
    </div>
  )
}
