/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Header from "../components/Header/Header";
import Menu from "../components/Menu/Menu";
import Banner from "../components/Banner/Banner";
import About from "../components/About/About";
import Portfolio from "../components/Portfolio/Portfolio";
import Contact from "../components/Contact/Contact";
import Skills from "../components/Skills/Skills";
import cv from "../assets/cv.pdf";
// import BlogList from "./BlogList/BlogList";

function HomePage() {
    const [menuOpen, setMenuOpen] = useState(false);
    const scrollTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setMenuOpen(false);
    };
    return (
        <div className="App">
            <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} cv={cv} />
            <Menu
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                scrollTop={scrollTop}
            />
            <div className="sections">
                <Banner cv={cv} />
                <About />
                <Skills />
                <Portfolio />
                {/* <BlogList /> */}
                <Contact scrollTop={scrollTop} />
            </div>
        </div>
    );
}

export default HomePage;
